import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "@core/services/storage/local-storage.service";
import { AccountingAccount } from "@data/schemas/accounting-account";
import { Account } from "@data/schemas/accounting-account/account";
import { AccountingAccountCategory } from "@data/schemas/accounting-account/accounting-account-category";
import { AccountingAccountVersion } from "@data/schemas/accounting-account/accounting-account-version";
import { DataList } from "@data/schemas/filter/data-list";
import { HttpParamsConfig } from "@shared/schemas/http-params-config";
import { convertHttpParamsToQueryString } from "@shared/utils/filter-params-utils";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class AccountingAccountService {
	constructor(
		private http: HttpClient,
		private localStorage: LocalStorageService
	) {}

	private readonly END_LIST_VALUE = "zzzzzz";
	private readonly ACCOUNTING_ACCOUNT_ENDPOINT = "accounting-accounts";
	private readonly GROUPED_ACCOUNTING_ACCOUNTS_ENDPOINT =
		"/get-accounting-accounts-grouped";
	private readonly localStorageOpenedModal = "newsChartAccountHasBeenOpened";

	private categoryCreatedSource = new Subject<void>();

	categoryCreated$ = this.categoryCreatedSource.asObservable();

	emitCategoryCreated() {
		this.categoryCreatedSource.next();
	}

	setNewsChartAccountHasBeenOpened() {
		this.localStorage.set(this.localStorageOpenedModal, "true");
	}

	get newsChartAccountHasBeenOpened(): boolean {
		return this.localStorage.get(this.localStorageOpenedModal) === "true";
	}

	private httpParamsSubject = new BehaviorSubject<HttpParamsConfig>({
		fields: []
	});
	httpParams$ = this.httpParamsSubject.asObservable();
	setHttpParams(params: HttpParamsConfig): void {
		this.httpParamsSubject.next(params);
	}

	findAll(
		searchText = "",
		accountingAccountType?: string
	): Observable<DataList<AccountingAccount>> {
		const params = {
			q: searchText,
			limit: "999",
			...(accountingAccountType && { accountingAccountType })
		};

		return this.http.get<DataList<AccountingAccount>>(
			this.ACCOUNTING_ACCOUNT_ENDPOINT,
			{
				params,
				withCredentials: true
			}
		);
	}

	getGroupedAccountingAccounts(
		httpParamsConfig?: HttpParamsConfig
	): Observable<Account[]> {
		const params = convertHttpParamsToQueryString({
			...httpParamsConfig
		});

		return this.http.get<Account[]>(
			this.ACCOUNTING_ACCOUNT_ENDPOINT +
				this.GROUPED_ACCOUNTING_ACCOUNTS_ENDPOINT,
			{
				params: params,
				withCredentials: true
			}
		);
	}

	createAccountingAccount(
		createCategory: AccountingAccountCategory
	): Observable<AccountingAccountCategory> {
		return this.http.post<AccountingAccountCategory>(
			this.ACCOUNTING_ACCOUNT_ENDPOINT,
			createCategory,
			{
				withCredentials: true
			}
		);
	}

	updateAccountingAccount(
		category: AccountingAccountCategory
	): Observable<AccountingAccountCategory> {
		return this.http.put<AccountingAccountCategory>(
			this.ACCOUNTING_ACCOUNT_ENDPOINT + "/" + category.id,
			category,
			{
				withCredentials: true
			}
		);
	}

	getAccountingAccountById(
		id: string
	): Observable<AccountingAccountCategory> {
		return this.http.get<AccountingAccountCategory>(
			this.ACCOUNTING_ACCOUNT_ENDPOINT + "/" + id,
			{
				withCredentials: true
			}
		);
	}

	deleteAccountingAccount(id: string): Observable<AccountingAccountCategory> {
		return this.http.delete<AccountingAccountCategory>(
			this.ACCOUNTING_ACCOUNT_ENDPOINT + "/" + id,
			{
				withCredentials: true
			}
		);
	}

	getAccountingAccountVersion(): Observable<AccountingAccountVersion> {
		return this.http.get<AccountingAccountVersion>(
			this.ACCOUNTING_ACCOUNT_ENDPOINT + "/version",
			{
				withCredentials: true
			}
		);
	}

	FilteredManualAccountingAccounts(
		accountingAccounts: AccountingAccount[]
	): AccountingAccount[] {
		return accountingAccounts.filter((account) => {
			return !account.bloquearConta;
		});
	}

	sortManualAccountingAccountsClassification(
		accountingAccounts: AccountingAccount[]
	): AccountingAccount[] {
		return accountingAccounts.sort((a, b) => {
			const classificacaoA = a.classificacao
				? a.classificacao.replace(/\./g, "")
				: this.END_LIST_VALUE;
			const classificacaoB = b.classificacao
				? b.classificacao.replace(/\./g, "")
				: this.END_LIST_VALUE;
			return classificacaoA.localeCompare(classificacaoB, undefined, {
				numeric: true
			});
		});
	}
}
